import React from 'react';
import {Button} from './Button';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
        <section className='footer-subscription'>
            <p className='footer-subscription-heading'>
                Lorem Ipsum
            </p>
            <p className='footer-subscription-text'>
                Lorem Ipsum
            </p>
            <div className='input-areas'>
                <form>
                    <input type='email' name='email' placeholder='Your Email' className='footer-input' />
                    <Button buttonStyle='btn--outline'>Subscribe</Button>
                </form>
            </div>
        </section>
        <div class='footer-links'>
            <div className='footer-link-wrapper'>
                <div class='footer-link-items'>
                    <h2>Lorem Ipsum</h2>
                        <Link to='/'>Lorem Ipsum</Link>
                        <Link to='/'>Lorem Ipsum</Link>
                </div> 
                <div class='footer-link-items'>
                    <h2>Lorem Ipsum</h2>
                        <Link to='/'>Lorem Ipsum</Link>
                        <Link to='/'>Lorem Ipsum</Link>
                </div>
                <div class='footer-link-items'>
                    <h2>Lorem Ipsum</h2>
                        <Link to='/'>Lorem Ipsum</Link>
                        <Link to='/'>Lorem Ipsum</Link>
                </div>        
            </div>
        </div>
        <section class='social-media'>
                    <div class='social-media-wrap'>
                        <small class='website-rights'>Property Visuals © 2024</small>
                        <div class='social-icons'>
                            <Link class='social-icon-link facebook' to='/' target='_blank' aria-label='Facebook'>
                                <i class='fab fa-facebook-f' />
                            </Link>
                            <Link class='social-icon-link instagram' to='/' target='_blank' aria-label='Instagram'>
                                <i class='fab fa-instagram' />
                            </Link>
                            <Link class='social-icon-link youtube' to='/' target='_blank' aria-label='Youtube'>
                                <i class='fab fa-youtube' />
                            </Link>
                            <Link class='social-icon-link twitter' to='/' target='_blank' aria-label='Twitter'>
                                <i class='fab fa-twitter' />
                            </Link>
                            <Link class='social-icon-link twitter' to='/' target='_blank' aria-label='LinkedIn'>
                                <i class='fab fa-linkedin' />
                            </Link>
                        </div>
                    </div>
                </section>
    </div>
  );
}

export default Footer;