import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './components/pages/Home.js';
import Services from './components/pages/Services.js';
import Contact from './components/pages/Contact.js';
import SignUp from './components/pages/SignUp.js';
import Footer from './components/Footer.js';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact Component={Home} />
          <Route path='/services' exact Component={Services} />
          <Route path='/contact' exact Component={Contact} />
          <Route path='/signup' exact Component={SignUp} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
